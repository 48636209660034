body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: Futura PT;
    font-weight: 300;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTLight.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 300;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTLightOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 350;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondBook.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 350;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondBookOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 400;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTBook.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 400;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTBookOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 450;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondMedium.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 450;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondMediumOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 500;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTMedium.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 500;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTMediumOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 600;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTDemi.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 600;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTDemiOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 650;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondBold.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 650;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondBoldOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 700;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTBold.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 700;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTBoldOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 850;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondExtraBold.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 850;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTCondExtraBoldOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 800;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTExtraBold.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 800;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTExtraBoldOblique.otf') format('truetype');
}

@font-face {
    font-family: Futura PT;
    font-weight: 900;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTHeavy.otf') format('truetype');
}

@font-face {
    font-family: Futura;
    font-style: italic;
    font-weight: 900;
    src: local('Futura PT'), url('utils/fonts/FuturaPT/FuturaPTHeavyOblique.otf') format('truetype');
}
