#root .primary_button {
    border: 2px solid #686EFF;
    filter: drop-shadow(0px 10px 40px rgba(0, 133, 255, 0.15));
    border-radius: 40px;
    color: #ffffff;
    min-width: max-content;
    font-family: 'Futura PT', sans-serif;
    font-size: 16px;
    letter-spacing: 0.2em;
    padding: 10px 27px;
}
